<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <div :class="$style.groupForm">
      <div :class="$style.left">
        <a-form-model-item label="权限组名称" prop="authorityGroupName">
          <a-input
            placeholder="请输入权限组名称"
            v-model="form.authorityGroupName"
            :maxLength="32"
          />
        </a-form-model-item>
        <a-form-model-item label="权限组类型" prop="groupType">
          <a-radio-group
            v-model="form.groupType"
            :disabled="operationType === 'edit'"
          >
            <a-radio :value="3" :disabled="canSelectHeadOffice">
              总公司
            </a-radio>
            <a-radio :value="2">
              子公司
            </a-radio>
            <a-radio :value="1">
              项目
            </a-radio>
          </a-radio-group>
          <span :class="$style.tipText">
            提示：已存在总公司则不可选择总公司，总公司/子公司权限组成员可查看公司及项目BI，项目则只可查看对应项目BI
          </span>
        </a-form-model-item>
        <a-form-model-item
          label="公司BI大屏组织名称"
          prop="organizationName"
          v-if="form.groupType !== 1"
        >
          <a-input
            placeholder="请输入BI大屏组织名称"
            v-model="form.organizationName"
            :maxLength="32"
          />
          <span :class="$style.tipText">
            提示：可输入公司、部门名称，BI大屏切换组织时可看到此名称
          </span>
        </a-form-model-item>
      </div>
      <div :class="$style.right">
        <a-form-model-item
          label="公司BI大屏地图显示"
          v-if="form.groupType !== 1"
        >
          <a-row :gutter="24">
            <a-col :span="12">
              <a-select
                v-model="form.provinceCode"
                @change="v => handleRegionChange(v, 'province')"
                placeholder="请选择省"
                allowClear
              >
                <a-select-option
                  v-for="item in provinceList"
                  :value="item.adcode"
                  :key="item.adcode"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col :span="12">
              <a-select
                v-model="form.cityCode"
                @change="v => handleRegionChange(v, 'city')"
                placeholder="请选择市"
                allowClear
              >
                <a-select-option
                  v-for="item in cityList"
                  :value="item.adcode"
                  :key="item.adcode"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-col>
          </a-row>
          <span :class="$style.tipText">
            不选择则展示全国，选择省则展示省，选择省市则展示市
          </span>
        </a-form-model-item>
        <a-form-model-item label="可看项目" prop="browseProjectId">
          <a-select
            mode="tags"
            v-model="form.browseProjectId"
            :class="$style.selectList"
          >
            <a-select-option v-for="item in projectList" :key="item.pkId">
              {{ item.projectName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="可看成员" prop="members">
          <x-member-select
            v-model="form.members"
            :view="view"
            :value="range"
            @input="updateRangeValue"
            :type="['org-user', 'role-user', 'user', 'external']"
            :limit="2"
            :multiple="true"
          />
        </a-form-model-item>
      </div>
    </div>
  </a-form-model>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  addGroup,
  editGroup,
  getProjectList,
} from '@/services/device-manage/authority-management';
import { getRegionList } from '@/services/device-manage/map';

@Component
export default class GroupForm extends Vue {
  @Prop({ type: String, default: 'add' }) operationType;
  @Prop({ type: Object, default: () => {} }) editData;
  @Prop({ type: String, default: '' }) tenantId;
  @Prop({ type: Array, default: () => [] }) groupList;

  get canSelectHeadOffice() {
    const disabled = this.groupList.some(item => item.groupType === 3);
    if (disabled) {
      this.form.groupType = 2;
    }
    return disabled;
  }
  form = {
    authorityGroupName: '',
    organizationName: '',
    groupType: 3,
    members: [],
    browseProjectId: [],
    city: '',
    cityCode: undefined,
    province: '',
    provinceCode: undefined,
  };
  rules = {
    authorityGroupName: [
      {
        required: true,
        message: '权限组名称不能为空',
      },
    ],
    organizationName: [
      {
        required: true,
        message: '公司BI大屏组织名称不能为空',
      },
    ],
    browseProjectId: [
      {
        required: true,
        message: '可看项目不能为空',
      },
    ],
    members: [
      {
        required: true,
        message: '可看成员不能为空',
      },
    ],
    groupType: [
      {
        required: true,
        message: '权限组不能为空',
      },
    ],
  };
  async mounted() {
    this.getProjectList();
    if (this.operationType === 'edit') {
      this.initFormData();
    }
    const { districts } = await getRegionList('中国');
    this.provinceList = districts[0].districts;
  }
  async initFormData() {
    if (this.editData.province) {
      const { districts } = await getRegionList(this.editData.province);
      this.cityList = districts[0].districts;
    }
    this.form.authorityGroupName = this.editData.authorityGroupName;
    this.form.organizationName = this.editData.organizationName;
    this.form.cityCode = this.editData.cityCode || undefined;
    this.form.groupType = this.editData.groupType;
    this.form.provinceCode = this.editData.provinceCode || undefined;
    this.form.province = this.editData.province;
    this.form.city = this.editData.city;
    this.form.members = this.editData.browseMemberVOList.map(item => ({
      text: item.memberName,
      value: item.pkId,
      avatar: item.avatar,
      type: 'user',
    }));
    this.form.browseProjectId = this.editData.browseProjectVOList.map(
      item => item.pkId,
    );
  }
  projectList = [];
  async getProjectList() {
    this.projectList = await getProjectList({
      tenantId: this.tenantId,
      groupId: this.editData.groupId,
    });
  }
  // 选择成员
  range = [];
  view = false;
  updateRangeValue(data) {
    this.range = data[0];
  }

  // 区域配置
  provinceList = [];
  cityList = [];
  async handleRegionChange(v, type) {
    if (type === 'province') {
      if (!v) {
        this.form.cityCode = undefined;
        this.cityList = [];
        return;
      }
      const idx = this.provinceList.findIndex(e => e.adcode === v);
      this.form.province = this.provinceList[idx]?.name;
      this.form.cityCode = undefined;
      const { districts } = await getRegionList(this.provinceList[idx]?.name);
      this.cityList = districts[0].districts;
    } else {
      const idx = this.cityList.findIndex(e => e.adcode === v);
      this.form.city = this.cityList[idx]?.name;
    }
  }

  async getValue() {
    const message = {
      add: '添加成功！',
      edit: '编辑成功',
    };
    await this.$refs.form.validate();
    try {
      const form = { ...this.form };
      // 与后端约定下拉框清空时(province/city/provinceCode/cityCode)不可以传undefined,需要传''
      form.province = this.form.provinceCode ? this.form.province : '';
      form.city = this.form.cityCode ? this.form.city : '';
      form.provinceCode = this.form.provinceCode || '';
      form.cityCode = this.form.cityCode || '';
      form.browseMemberId = this.form.members.map(item => item.value);
      form.tenantId = this.tenantId;
      delete form.members;
      this.operationType === 'edit'
        ? await editGroup({ pkId: this.editData.groupId, ...form })
        : await addGroup(form);
      this.$message.success(message[this.operationType]);
      return true;
    } catch (error) {
      return false;
    }
  }
}
</script>

<style lang="less" module>
.groupForm {
  display: flex;
  justify-content: space-between;
  .left,
  .right {
    width: 49%;
  }
}
.tipText {
  display: block;
  font-size: 13px;
  color: #999999;
}
.selectList {
  :global {
    .ant-select-selection--multiple {
      max-height: 60px;
      overflow-y: auto;
    }
  }
}
</style>
