<template>
  <div :class="$style.container">
    <div :class="$style.sidebar">
      <div :class="$style.top">
        <span :class="$style.title">导航栏BI权限配置</span>
      </div>
      <div :class="$style.groups">
        <a-select
          v-model="group"
          placeholder="权限组类型"
          :class="$style.groupSelect"
          :options="groups"
          @change="handleChange"
        />
        <a-spin :spinning="loading">
          <EmptyContent v-if="!groupList.length" />
          <div :class="$style.groupList">
            <div
              :class="[
                $style.group,
                index === activeIndex ? $style.selected : '',
              ]"
              v-for="(item, index) in groupList"
              :key="index"
              @click="onSelectGroup(item, index)"
            >
              <div :class="$style.name">
                {{ item.authorityGroupName }}
              </div>
              <span :class="$style.label">{{
                ['', '项目', '子公司', '总公司'][item.groupType]
              }}</span>
            </div>
          </div>
        </a-spin>
        <a-button
          type="primary"
          @click="addPermissionGroup"
          v-if="$p.action('CREATE')"
          >添加权限组</a-button
        >
      </div>
    </div>
    <div :class="$style.content">
      <div :class="$style.top">
        <span :class="$style.title">{{
          groupDetail.groupType !== 1
            ? `公司大屏组织名称：${groupDetail.organizationName || ''}`
            : ''
        }}</span>
        <div :class="$style.buttons">
          <a-button
            type="primary"
            ghost
            @click="deleteGroup()"
            v-if="$p.action('DELETE')"
            >删除</a-button
          >
          <a-button
            type="primary"
            @click="editTemplate"
            v-if="$p.action('UPDATE')"
            >编辑</a-button
          >
        </div>
      </div>
      <div :class="$style.groupPermission">
        <a-spin :spinning="DLoading">
          <template v-if="groupDetail.groupType !== 1">
            <div :class="$style.permission">
              <div :class="$style.name">
                公司BI大屏菜单({{
                  getTotal(groupDetail.allMenuVOTreeListMap?.['2'])
                }})<a
                  @click="openMenuConfigModal('compony')"
                  v-if="$p.action('UPDATE')"
                  >配置</a
                >
              </div>
              <div :class="$style.auth">
                <div
                  :class="$style.item"
                  v-for="item in groupDetail.allMenuVOTreeListMap?.['2']"
                  :key="item.menuId"
                >
                  {{ item.menuName }}
                </div>
              </div>
            </div>
            <div
              :class="$style.permission"
              v-for="item in componyChildrenMenuList"
              :key="item.menuId"
            >
              <div :class="[$style.name, $style.childName]">
                {{ item.menuName }}
              </div>
              <div :class="$style.auth">
                <div
                  :class="$style.item"
                  v-for="itemChild in item.children"
                  :key="itemChild.menuId"
                >
                  {{ itemChild.menuName }}
                </div>
              </div>
            </div>
          </template>
          <div :class="$style.permission" style="margin-bottom: 10px;">
            <div :class="$style.name">
              项目BI大屏菜单({{
                getTotal(groupDetail.allMenuVOTreeListMap?.['1'])
              }})<a
                @click="openMenuConfigModal('project')"
                v-if="$p.action('UPDATE')"
                >配置</a
              >
            </div>
            <div :class="$style.auth">
              <div
                :class="$style.item"
                v-for="item in groupDetail.allMenuVOTreeListMap?.['1']"
                :key="item.menuId"
              >
                {{ item.menuName }}
              </div>
            </div>
          </div>
          <div
            :class="$style.permission"
            v-for="item in projectChildrenMenuList"
            :key="item.menuId"
          >
            <div :class="[$style.name, $style.childName]">
              {{ item.menuName }}
            </div>
            <div :class="$style.auth">
              <div
                :class="$style.item"
                v-for="itemChild in item.children"
                :key="itemChild.menuId"
              >
                {{ itemChild.menuName }}
              </div>
            </div>
          </div>
          <div :class="$style.permission">
            <div :class="$style.name">
              可看项目({{ groupDetail.browseProjectVOList.length }})
            </div>
            <div :class="$style.auth">
              <div
                :class="$style.item"
                v-for="item in groupDetail.browseProjectVOList"
                :key="item.pkId"
              >
                {{ item.projectName }}
              </div>
            </div>
          </div>
          <div :class="$style.permission">
            <div :class="$style.name">
              可看成员({{ groupDetail.browseMemberVOList.length }})
            </div>
            <div :class="$style.auth">
              <div
                :class="[$style.item, $style.userTag]"
                v-for="item in groupDetail.browseMemberVOList"
                :key="item.pkId"
              >
                <template>
                  <x-oss-image
                    :class="$style.avatar"
                    v-if="item.avatar"
                    :ossPath="item.avatar"
                  />
                  <span :class="[$style.avatar, $style.noAvatar]" v-else>{{
                    item.memberName.charAt(0)
                  }}</span>
                </template>
                <span :class="$style.name">{{ item.memberName }}</span>
              </div>
            </div>
          </div>
        </a-spin>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import { createFormModal } from '@triascloud/x-components';
import MenuConfig from './form-modal/menu-config.vue';
import GroupForm from './form-modal/group-form.vue';
import Tip from '@/components/tip';
import {
  getGroupList,
  deleteGroup,
  getGroupDetail,
} from '@/services/device-manage/authority-management';
import { crossStorageModule } from '@/enum/store.js';

@Component({
  components: {},
})
export default class NavPermissions extends Vue {
  @crossStorageModule.State('tenant') tenant;
  mounted() {
    this.getGroupList();
  }
  get componyChildrenMenuList() {
    const list = this.groupDetail.allMenuVOTreeListMap?.['2'] || [];
    return list.filter(item => item.menuLevel === 'FIRST_EMPTY');
  }
  get projectChildrenMenuList() {
    const list = this.groupDetail.allMenuVOTreeListMap?.['1'] || [];
    return list.filter(item => item.menuLevel === 'FIRST_EMPTY');
  }
  groups = [
    {
      label: '全部',
      value: '',
    },
    {
      label: '总公司',
      value: 3,
    },
    {
      label: '子公司',
      value: 2,
    },
    {
      label: '项目',
      value: 1,
    },
  ];
  activeIndex = 0;
  selectGroup = {};
  group = '';
  loading = false;
  groupList = [];
  async getGroupList() {
    const params = {
      current: 1,
      size: 300,
      groupType: this.group,
    };
    try {
      this.loading = true;
      const { records } = await getGroupList(params);
      this.groupList = records;
      if (this.groupList.length) {
        this.selectGroup = this.groupList[this.activeIndex];
        await this.getGroupDetail();
      }
    } finally {
      this.loading = false;
    }
  }
  handleChange() {
    this.activeIndex = 0;
    this.getGroupList();
  }
  async onSelectGroup(row, index) {
    this.activeIndex = index;
    this.selectGroup = row;
    await this.getGroupDetail();
  }
  DLoading = false;
  groupDetail = {
    browseMemberVOList: [],
    browseProjectVOList: [],
    parentMenuVOList: [],
  };
  async getGroupDetail() {
    try {
      this.DLoading = true;
      this.groupDetail = await getGroupDetail(this.selectGroup.groupId);
    } finally {
      this.DLoading = false;
    }
  }
  async openMenuConfigModal(type) {
    try {
      const typeObj = {
        compony: '公司BI大屏菜单配置',
        project: '项目BI大屏菜单配置',
      };
      const result = await createFormModal(
        () => (
          <MenuConfig
            tenantId={this.tenant?.pkId}
            groupId={this.selectGroup.groupId}
            configType={type === 'project' ? 1 : 2}
          />
        ),
        {
          width: 638,
          maskClosable: false,
          title: typeObj[type],
        },
      );
      if (result) {
        this.getGroupDetail();
      }
    } catch {
      return false;
    }
  }
  addPermissionGroup() {
    this.showTemplateModal({}, 'add');
  }
  editTemplate() {
    const { authorityGroupName, groupType } = this.selectGroup;
    this.showTemplateModal(
      { authorityGroupName, groupType, ...this.groupDetail },
      'edit',
    );
  }
  async showTemplateModal(data, operationType) {
    try {
      const result = await createFormModal(
        () => (
          <GroupForm
            editData={data}
            operationType={operationType}
            tenantId={this.tenant?.pkId}
            groupList={this.groupList}
          />
        ),
        {
          width: 900,
          maskClosable: false,
          title: operationType === 'add' ? '添加权限组' : '编辑权限组',
        },
      );
      if (result) {
        await this.getGroupList();
      }
    } catch {
      return false;
    }
  }
  async deleteGroup() {
    const text = `确定删除权限组-${this.selectGroup.authorityGroupName}？`;
    const tips = '删除后将清空权限组可查看的权限，请谨慎删除';
    try {
      await createFormModal(
        () => (
          <Tip iconStyle={{ padding: '0 0 22px' }}>
            <template slot="txt">
              <span>{text}</span>
            </template>
            <span slot="subTxt">{tips}</span>
          </Tip>
        ),
        {
          width: '442px',
          title: '提示',
          onOk: async () => {
            await deleteGroup(this.selectGroup.groupId);
            this.$message.success('删除成功!');
            this.getGroupList();
            this.activeIndex = 0;
          },
        },
      );
    } catch {
      return false;
    }
  }
  getTotal(array = []) {
    let total = array.length;
    array.forEach(element => {
      total += element.children.length;
    });
    return total;
  }
}
</script>

<style lang="less" module>
.container {
  display: flex;
  height: 100%;
  .sidebar,
  .content {
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 57px;
      padding: 0 20px;
      border-bottom: 1px solid var(--border);
      .title {
        font-size: 16px;
      }
    }
  }
  .sidebar {
    width: 430px;
    border-right: 1px solid var(--border);
    .groups {
      height: calc(100% - 57px);
      display: flex;
      flex-direction: column;
      position: relative;
      .groupSelect {
        width: 390px;
        margin: 20px 0 20px 20px;
      }
      .groupList {
        flex: 1;
        min-height: 50vh;
        overflow-y: auto;
        .group {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 20px 10px 34px;
          cursor: pointer;
          .name {
            font-size: 14px;
            width: 290px;
          }
          .label {
            padding: 4px 12px;
            border-radius: 5px;
            border: 1px solid var(--border);
            font-size: 14px;
          }
        }
        .selected {
          background-color: var(--inline-menu-active);
          position: relative;
          .name {
            color: var(--primary);
          }
          .label {
            color: #ffffff;
            background-color: var(--primary);
          }
        }
        .selected::before {
          content: '';
          height: 100%;
          width: 1px;
          border: 2px solid var(--primary);
          position: absolute;
          left: 0;
        }
      }
      & > button {
        position: absolute;
        right: 20px;
        bottom: 20px;
      }
    }
  }
  .content {
    flex: 1;
    .top {
      .buttons {
        button {
          height: 32px;
          margin-left: 10px;
        }
      }
    }
    .groupPermission {
      height: calc(100% - 57px);
      overflow-y: auto;
      padding: 20px;
      .permission {
        margin-bottom: 20px;
        & > .name {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          font-size: 16px;
          & > a {
            font-size: 16px;
          }
        }
        .childName {
          font-size: 14px;
          margin: 0 0 0px 15px;
        }
        .auth {
          display: flex;
          flex-wrap: wrap;
          // max-height: 100px;
          min-height: 63px;
          // overflow-y: auto;
          border: 1px dashed var(--steps-line);
          border-radius: 5px;
          padding: 15px 15px 5px;
          .item {
            padding: 4px 12px;
            border-radius: 5px;
            margin: 0 10px 10px 0;
            border: 1px solid var(--border);
            font-size: 14px;
          }
          .userTag {
            display: flex;
            align-items: center;
            background: var(--tag);
            border-radius: 4px;
            .avatar {
              height: 24px;
              width: 24px;
              border-radius: 50%;
              object-fit: cover;
            }
            .noAvatar {
              color: #fff;
              background-color: var(--x-modal-select-avatar);
              text-align: center;
              line-height: 22px;
              font-size: 12px;
            }

            .name {
              margin: 0 5px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
</style>
