<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <a-form-model-item label="菜单名称" prop="menuName">
      <a-input
        placeholder="请输入菜单名称"
        v-model="form.menuName"
        :maxLength="16"
      />
    </a-form-model-item>
    <a-form-model-item label="菜单级别" prop="menuLevel">
      <a-radio-group
        v-model="form.menuLevel"
        :disabled="operationType === 'edit'"
        :options="menuLevelList"
      />
      <span :class="$style.tipText" v-if="form.menuLevel === 'FIRST_EMPTY'">
        提示：空白菜单并无功能，只为展示二级菜单
      </span>
      <a-select
        v-model="form.parentId"
        placeholder="请选择一级空白菜单"
        v-if="form.menuLevel === 'SECOND'"
      >
        <a-select-option
          :value="item.menuId"
          v-for="item in emptyMenus"
          :key="item.navigationTag"
        >
          {{ item.menuName }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item
      label="菜单类型"
      prop="menuType"
      v-if="this.form.menuLevel !== 'FIRST_EMPTY'"
    >
      <a-radio-group v-model="form.menuType">
        <a-radio :value="1">
          标准页面
        </a-radio>
        <a-radio :value="2">
          自定义链接
        </a-radio>
      </a-radio-group>
      <a-form-model-item prop="navigationTag" v-if="form.menuType === 1">
        <a-select
          v-model="form.navigationTag"
          placeholder="请选择功能"
          show-search
          option-filter-prop="children"
          :filter-option="true"
          @change="handleSelectChange"
        >
          <a-select-option
            :value="item.navigationTag"
            v-for="item in menuTypeList"
            :key="item.navigationTag"
          >
            {{ navMenu[item.navigationTag] }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop="templateId" v-if="form.menuType === 1">
        <a-select v-model="form.templateId" placeholder="请选择模板页面">
          <a-select-option
            :value="item.pkId"
            v-for="item in templateList"
            :key="item.pkId"
          >
            {{ item.templateName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop="url" v-if="form.menuType === 2">
        <a-input placeholder="请输入自定义链接" v-model="form.url" />
      </a-form-model-item>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  addMenu,
  editMenu,
  getMenuTypeList,
} from '@/services/device-manage/authority-management';

/**
 * 导航栏菜单
 */
const navMenu = {
  GONG_SI_SHOU_YE: '首页',
  XIANG_MU_SHOU_YE: '首页',
  LV_SE_SHI_GONG: '绿色施工',
  LAO_WU_GUAN_LI: '劳务管理',
  SHI_PING_JIAN_KONG: '视频监控',
  CHE_LIANG_GUAN_LI: '车辆管理',
  XIAN_CHANG_JIAN_CHA: '现场检查',
  AN_QUAN_JIAN_CE: '安全监测',
  SHENG_JIANG_JI: '升降机',
  XIE_LIAO_PING_TAI: '卸料平台',
  TA_DIAO: '塔吊',
  LIN_BIAN_FANG_HU: '临边防护',
  SHEN_JI_KENG: '深基坑',
  GAO_ZHI_MO: '高支模',
  ZHI_NENG_DI_BANG: '智能地磅',
  SHUI_DIAN_JIAN_CE: '水电监测',
  AI_SHI_BIE: 'AI识别',
  JIN_DU_GUAN_LI: '进度管理',
};

@Component
export default class MenuForm extends Vue {
  @Prop({ type: Object, default: () => {} }) editData;
  @Prop({ type: String, default: 'add' }) operationType;
  @Prop({ type: String, default: '' }) tenantId;
  @Prop({ type: String, default: '' }) groupId;
  @Prop({ type: Array, default: () => [] }) emptyMenus;
  @Prop({ type: Number, default: 1 }) configType;

  form = {
    menuLevel: 'FIRST',
    menuName: '',
    menuType: 1,
    navigationTag: undefined,
    parentId: undefined,
    templateId: undefined,
    url: '',
  };
  menuLevelList = [
    {
      label: '一级菜单',
      value: 'FIRST',
    },
    {
      label: '一级空白菜单',
      value: 'FIRST_EMPTY',
    },
    {
      label: '二级菜单',
      value: 'SECOND',
    },
  ];
  rules = {
    menuName: [
      {
        required: true,
        message: '菜单名称不能为空',
      },
    ],
    menuLevel: [
      {
        required: true,
        message: '菜单级别不能为空',
      },
    ],
    menuType: [
      {
        required: true,
        message: '菜单类型不能为空',
      },
    ],
    navigationTag: [
      {
        required: true,
        message: '功能不能为空',
      },
    ],
    templateId: [
      {
        required: true,
        message: '模板页面不能为空',
      },
    ],
    url: [
      {
        required: true,
        message: '自定义链接不能为空',
      },
    ],
  };
  navMenu = navMenu;
  async mounted() {
    await this.getMenuTypes();
    if (this.operationType === 'edit') {
      this.initFormData();
    }
  }
  initFormData() {
    const temObj = this.menuTypeList.find(
      item => item.navigationTag === this.editData.navigationTag,
    );
    this.templateList = temObj ? [temObj] : [];
    Object.keys(this.form).forEach(key => {
      this.form[key] = this.editData[key];
    });
  }
  menuTypeList = [];
  templateList = [];
  async getMenuTypes() {
    try {
      this.menuTypeList = await getMenuTypeList({
        tenantId: this.tenantId,
        templateType: this.configType,
      });
    } catch {
      return false;
    }
  }
  handleSelectChange(v) {
    const temObj = this.menuTypeList.find(item => item.navigationTag === v);
    this.templateList = [temObj];
    this.form.templateId = temObj.pkId;
  }
  async getValue() {
    const message = {
      add: '添加成功！',
      edit: '编辑成功',
    };
    await this.$refs.form.validate();
    this.form.groupId = this.groupId;
    this.form.tenantId = this.tenantId;
    this.form.configType = this.configType;
    try {
      this.operationType === 'edit'
        ? await editMenu({ menuId: this.editData.menuId, ...this.form })
        : await addMenu(this.form);
      this.$message.success(message[this.operationType]);
      return true;
    } catch (error) {
      return false;
    }
  }
}
</script>

<style lang="less" module>
.tipText {
  display: block;
  font-size: 13px;
  color: #999999;
}
</style>
