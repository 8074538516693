<template>
  <div :class="$style.menuConfig">
    <div :class="$style.top">
      <div :class="$style.name">菜单栏</div>
      <div :class="$style.description">根据从上往下的规则展示菜单顺序</div>
      <a-button type="primary" @click="addMenu">添加菜单</a-button>
    </div>
    <div :class="$style.config">
      <a-spin :spinning="loading">
        <EmptyContent v-if="!menuList.length" />
        <draggable
          v-model="menuList"
          chosen-class="chosen"
          force-fallback="true"
          group="people"
          animation="500"
          handle=".action-item"
          @start="onStart"
          @end="onEnd"
        >
          <transition-group>
            <div
              :class="$style.menu"
              v-for="item in menuList"
              :key="item.menuId"
            >
              <div :class="$style.item">
                <div :class="$style.name">
                  <x-icon
                    class="action-item"
                    :class="$style.dragIcon"
                    type="tc-icon-drag-handler"
                  ></x-icon>
                  {{ item.menuName }}
                  <a-icon
                    :class="$style.arrowIcon"
                    @click="item.isOpen = !item.isOpen"
                    v-if="item.children.length"
                    :type="item.isOpen ? 'caret-down' : 'caret-right'"
                  />
                </div>
                <div :class="$style.operation">
                  <a-icon type="form" @click="editMenu(item)" />
                  <a-icon type="delete" @click="deleteMenu(item)" />
                </div>
              </div>
              <template v-if="item.isOpen">
                <draggable
                  v-model="item.children"
                  chosen-class="chosen"
                  force-fallback="true"
                  :group="item.menuId"
                  animation="500"
                  handle=".action-item"
                  @start="onStart"
                  @end="onEnd"
                >
                  <transition-group>
                    <div
                      :class="$style.child"
                      v-for="itemChild in item.children"
                      :key="itemChild.menuId"
                    >
                      <div :class="[$style.item, $style.childItem]">
                        <div :class="$style.name">
                          <x-icon
                            class="action-item"
                            :class="$style.dragIcon"
                            type="tc-icon-drag-handler"
                          ></x-icon>
                          {{ itemChild.menuName }}
                        </div>
                        <div :class="$style.operation">
                          <a-icon type="form" @click="editMenu(itemChild)" />
                          <a-icon
                            type="delete"
                            @click="deleteMenu(itemChild)"
                          />
                        </div>
                      </div>
                    </div>
                  </transition-group>
                </draggable>
              </template>
            </div>
          </transition-group>
        </draggable>
      </a-spin>
    </div>
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import { createFormModal } from '@triascloud/x-components';
import MenuForm from './menu-form.vue';
import {
  getMenuList,
  deleteMenu,
  updateMenuSortList,
} from '@/services/device-manage/authority-management';
import Tip from '@/components/tip';

@Component
export default class MenuConfig extends Vue {
  @Prop({ type: String, default: '' }) tenantId;
  @Prop({ type: String, default: '' }) groupId;
  @Prop({ type: Number, default: 1 }) configType;

  mounted() {
    this.getMenus();
  }
  loading = false;
  menuList = [];
  emptyMenus = [];
  async getMenus() {
    try {
      this.loading = true;
      this.menuList = (
        await getMenuList({
          tenantId: this.tenantId,
          groupId: this.groupId,
          configType: this.configType,
        })
      ).map(item => {
        item.isOpen = true;
        return item;
      });
      this.emptyMenus = this.menuList.filter(
        item => item.menuLevel === 'FIRST_EMPTY',
      );
    } finally {
      this.loading = false;
    }
  }
  onStart() {}
  menuOrderBOList = [];
  onEnd() {
    this.menuOrderBOList = [];
    this.menuList.forEach((item, index) => {
      item.menuOrder = index + 1;
      this.menuOrderBOList.push({
        menuId: item.menuId,
        menuOrder: item.menuOrder,
      });
      item.children.forEach((itemChild, index) => {
        itemChild.menuOrder = index + 1;
        this.menuOrderBOList.push({
          menuId: itemChild.menuId,
          menuOrder: itemChild.menuOrder,
        });
      });
    });
  }
  addMenu() {
    this.openMenuFormModal({}, 'add');
  }
  editMenu(data) {
    this.openMenuFormModal(data, 'edit');
  }
  async openMenuFormModal(data, operationType) {
    try {
      const result = await createFormModal(
        () => (
          <MenuForm
            editData={data}
            operationType={operationType}
            tenantId={this.tenantId}
            groupId={this.groupId}
            configType={this.configType}
            emptyMenus={this.emptyMenus}
          />
        ),
        {
          width: 440,
          maskClosable: false,
          title: operationType === 'add' ? '添加菜单' : '编辑菜单',
        },
      );
      if (result) {
        await this.getMenus();
      }
    } catch {
      return false;
    }
  }
  async deleteMenu(data) {
    const text = `确定删除${data.menuName}`;
    const tips = '删除后将无此菜单，请谨慎删除';
    try {
      await createFormModal(
        () => (
          <Tip iconStyle={{ padding: '0 0 22px' }}>
            <template slot="txt">
              <span>{text}</span>
            </template>
            <span slot="subTxt">{tips}</span>
          </Tip>
        ),
        {
          width: '442px',
          title: '提示',
          onOk: async () => {
            await deleteMenu(data.menuId);
            this.$message.success('删除成功!');
            this.getMenus();
          },
        },
      );
    } catch {
      return false;
    }
  }
  async getValue() {
    try {
      this.onEnd();
      await updateMenuSortList({ menuOrderBOList: this.menuOrderBOList });
      this.$message.success('配置成功!');
      return true;
    } catch (error) {
      return false;
    }
  }
}
</script>

<style lang="less" module>
.menuConfig {
  .top {
    display: flex;
    align-items: center;
    .name {
      font-size: 16px;
    }
    .description {
      margin-left: 10px;
      color: var(--font-info);
    }
    & > button {
      margin-left: auto;
    }
  }
  .config {
    height: 408px;
    margin-top: 20px;
    overflow-y: auto;
    padding: 20px;
    border: 1px solid var(--border);
    border-radius: 4px;
    .menu {
      .item {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        .name {
          .dragIcon {
            color: var(--font);
            font-size: 16px;
            cursor: move;
          }
          .arrowIcon {
            cursor: pointer;
          }
          .dragIcon:hover,
          .arrowIcon:hover {
            color: var(--primary);
          }
        }
        .operation {
          margin-left: auto;
          svg {
            font-size: 16px;
            margin-left: 10px;
          }
          svg:hover {
            cursor: pointer;
            color: var(--primary);
          }
        }
      }
      .child {
        .childItem {
          padding-left: 20px;
        }
      }
    }
  }
}
</style>
