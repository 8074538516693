import request from '../request';
const basePath = '/smart-site/navigate/authority';

// 权限组
export const getGroupList = params =>
  request(`${basePath}/pageGroupData`, {
    method: 'POST',
    body: params,
  });

export const getGroupDetail = groupId =>
  request(`${basePath}/getDetailsByGroupId/${groupId}`);

export const addGroup = params =>
  request(`${basePath}/addGroup`, {
    method: 'POST',
    body: params,
  });

export const editGroup = params =>
  request(`${basePath}/updateGroup`, {
    method: 'POST',
    body: params,
  });

export const deleteGroup = id =>
  request(`${basePath}/deleteGroup/${id}`, {
    method: 'DELETE',
  });

// 菜单
export const getMenuList = params =>
  request(`${basePath}/getNavigateMenuOrderList`, {
    method: 'GET',
    body: params,
  });

export const addMenu = params =>
  request(`${basePath}/addMenu`, {
    method: 'POST',
    body: params,
  });

export const editMenu = params =>
  request(`${basePath}/updateMenu`, {
    method: 'POST',
    body: params,
  });

export const deleteMenu = menuId =>
  request(`${basePath}/deleteMenu/${menuId}`, {
    method: 'DELETE',
  });

export const getMenuTypeList = params =>
  request(`${basePath}/getTemplateList`, {
    method: 'GET',
    body: params,
  });

// 修改菜单顺序
export const updateMenuSortList = params =>
  request(`${basePath}/updateMenuSortList`, {
    method: 'POST',
    body: params,
  });

// 获取项目列表
export const getProjectList = params =>
  request(`${basePath}/getProjectList`, {
    method: 'GET',
    body: params,
  });
